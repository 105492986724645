<template>
  <AView
    :value="value"
    :name="service.name"
    :active="service.id === activeServiceId"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onNavigate="onNavigate"
    @onSave="onUseCasesUpdate"
    @onClear="onClear"
  >
    <template v-slot:content="{}">
      <v-card class="pa-2 transparent elevation-0">
        <ServiceSectionFormUseCasesVue
          ref="form"
          v-model="service.useCases"
          @onUseCaseCreate="onUseCaseCreate"
          @onUseCaseRemove="onUseCaseRemove"
        ></ServiceSectionFormUseCasesVue>
      </v-card>
    </template>
  </AView>
</template>
    
    <script>
import { mapGetters, mapState } from "vuex";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";
import ServiceSectionFormUseCasesVue from "../../../../../../../components/wad/organisms/services/editorForms/ServiceSectionFormUseCases.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    ServiceSectionFormUseCasesVue,
  },
  data: () => ({
    service: {},
  }),
  created() {
    this.$store.dispatch("ServiceStore/GetService", {
      id: this.value.relation.params.serviceId,
    });
    this.service = this.serviceById(this.value.relation.params.serviceId);
  },

  computed: {
    ...mapState("ServiceStore", ["activeServiceId", "displayedServices"]),
    ...mapGetters("ServiceStore", ["serviceById"]),
  },

  methods: {
    onFocus() {},
    onNavigate() {
      this.$store.dispatch("ServiceStore/setActiveSection", {
        serviceId: this.value.relation.params.serviceId,
        section: "use_cases",
      });
    },
    onSave() {},
    onClose() {},
    onClear() {},
    // ==============USE CASES===============
    async onUseCaseCreate() {
      this.$store.dispatch("ServiceStore/onUseCaseCreate", {
        service: this.service,
      });
    },
    async onUseCaseRemove(useCase) {
      this.$store.dispatch("ServiceStore/onUseCaseRemove", {
        service: this.service,
        useCase,
      });
    },
    async onUseCasesUpdate() {
      if (this.$refs.useCases.validate()) {
        this.$store.dispatch("ServiceStore/onUseCasesUpdate", {
          service: this.service,
        });
      }
    },
  },
  watch: {
    displayedServices: {
      async handler(newVal) {
        if (newVal) {
          this.service = this.serviceById(this.value.relation.params.serviceId);
        }
      },
      deep: true,
    },
    service: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("ServiceStore/UpdateService", {
            service: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>