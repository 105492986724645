<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <FormSliderVue
      v-model="value"
      :addText="'Add New Use Case'"
      @onAdd="onAddStep"
      @onRemove="onStepRemove"
    >
      <template v-slot:form="{ item, attrs }">
        <v-row v-bind="attrs">
          <v-col class="use-cases-col" cols="12" lg="6">
            <SelectorIconsVue
              dark
              :helpText="'Please select icon that will be associated with Use Case'"
              v-model="item.icon"
            >
            </SelectorIconsVue>
            <HelpFormInputVue
              dark
              v-model="item.name"
              label="Use Case Title"
              required
            ></HelpFormInputVue>
          </v-col>
          <v-col class="use-cases-col" cols="12" lg="6">
            <HelpFormInputVue
              dark
              textarea
              :rows="5"
              v-model="item.description"
              label="Use Case Description"
              required
            ></HelpFormInputVue>
          </v-col>
        </v-row>
      </template>
    </FormSliderVue>
  </v-form>
</template>


<script>
import SelectorIconsVue from "../../../atoms/common/autocompletes/SelectorIcons.vue";
import FormSliderVue from "../../../atoms/common/FormSlider.vue";
import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    }
  },
  data() {
    return {
      valid: true,
      formData: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Section Name is required"],

      serviceLevelRules: [(v) => !!v || "Service Level is required"],
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceCodeRules: [(v) => !!v || "Service Name is required"],
      serviceBtnRules: [(v) => !!v || "Action Button Text is required"],
      serviceDescriptionRules: [(v) => !!v || "Description is required"],
    };
  },
  components: {
    HelpFormInputVue,
    FormSliderVue,
    SelectorIconsVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onAddStep() {
      this.$emit("onUseCaseCreate");
    },
    onStepRemove(useCase) {
      this.$emit("onUseCaseRemove", useCase);

    },
  },
  watch: {
    formData: {
      handler(newVal) {
        this.$emit("onChange", newVal);
      },
      deep: true,
    },
  },
};
</script>


<style lang="scss" scoped>
.use-cases-col{
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>